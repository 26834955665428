import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Inject } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import firebase from 'firebase/app';
import { CookieService } from '@shared/service/cookie/cookie.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  order: any;
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AngularFireAuth,
    private cookieService: CookieService
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.order = { ...params.keys, ...params };
      this.logout();
    });
  }

  ngOnInit(): void {}
  logout(): void {
    let target_url: string = this.order.params.target;
    if (!target_url) {
      target_url = this.document.referrer;
    }
    if (target_url.match(/logout/)) {
      target_url = this.document.baseURI;
    }
    this.cookieService.deleteDCMemberStatusCookie();
    this.auth.signOut().then(() => {
      location.href = target_url;
    });
  }
}
