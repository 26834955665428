import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/logins/login-staff/login.component';
import { LoginFadminComponent } from './core/logins/login-fadmin/login-fadmin.component';
import { LoginAdminComponent } from './core/logins/login-admin/login-admin.component';
import { LogoutComponent } from './core/logout/logout.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // 問い合わせ
  { path: 'support', redirectTo: 'public/support' },
  // 利用規約
  { path: 'agreement', redirectTo: 'public/agreement' },
  // 要件追加のため、publicmoduleを追加
  {
    path: 'public',
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },

  // ログイン関連
  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'fadmin/login', component: LoginFadminComponent },
  { path: 'admin/login', component: LoginAdminComponent },

  // 権限ごとの読み込み
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'fadmin',
    loadChildren: () =>
      import('./modules/fadmin/fadmin.module').then((m) => m.FadminModule),
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./modules/staff/staff.module').then((m) => m.StaffModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
