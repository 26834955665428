import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { toast } from 'bulma-toast';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss'],
})
export class LoginAdminComponent implements OnInit {
  username = '';
  password = '';
  passwordHide = true;

  constructor(public auth: AngularFireAuth, private router: Router) {}

  ngOnInit(): void {
    this.auth.onAuthStateChanged((user: firebase.User) => {
      user.getIdToken().then((idToken: string) => {
        console.log(idToken);
      });
    });
  }

  login(): void {
    this.auth
      .signInWithEmailAndPassword(this.username, this.password)
      .then(() => {
        this.router.navigateByUrl('/admin/top');
      })
      .catch((reason) => {
        toast({
          message: this.authErrorMessage(reason),
          type: 'is-danger',
          position: 'center',
          dismissible: true,
          closeOnClick: true,
          pauseOnHover: true,
          animate: { in: 'fadeInUp', out: 'fadeOutUp' },
        });
      });
  }

  logout(): void {}

  private authErrorMessage(reason: any): string {
    const errorCode = reason.code;
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'メールアドレスが不正です';
      case 'auth/user-disabled':
        return 'このアカウントは無効です';
      case 'auth/user-not-found':
        return '指定したアカウントは見つかりませんでした';
      case 'auth/wrong-password':
        return 'パスワードが違います';
      default:
        return '不明なエラー';
    }
  }

  onClickPasswordHide(): void {
    this.passwordHide = !this.passwordHide;
  }
}
