export const environment = {
  production: false,
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyDiiARcNmr2pGrCZE5dhyZt3hWWd9vJnCo',
    authDomain: 'test-almedia-movies.firebaseapp.com',
    databaseURL: 'https://test-almedia-movies.firebaseio.com',
    projectId: 'test-almedia-movies',
    storageBucket: 'test-almedia-movies.appspot.com',
    messagingSenderId: '1050270309371',
    appId: '1:1050270309371:web:5bebbdd97f64d6124a095e',
  },
  algolia: {
    appId: '2GPXG43HGL',
    apiKey: '048c9143bfd741a4bae57257c1bc6ceb',
  },
  dearcare: 'https://dearcare.almediaweb.jp/home/member/',
  spiralmenu:
    'https://area18.smp.ne.jp/area/p/pbqg6qetis8linjod3/0hNVDh/login.html',
  spiralPassword:
    'https://reg18.smp.ne.jp/regist/is?SMPFORM=pbqg-lgmboa-870d37d6411ae34fd2ee360e76459f11',
};
