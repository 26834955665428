import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { CookieService } from '@shared/service/cookie/cookie.service';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { environment } from '@env';

@Component({
  selector: 'app-login-fadmin',
  templateUrl: './login-fadmin.component.html',
  styleUrls: ['./login-fadmin.component.scss'],
})
export class LoginFadminComponent implements OnInit {
  username = '';
  password = '';
  passwordHide = true;
  progressing = false;
  passwordForgotMessage = false;
  spiralPasswordReminderUrl = environment.spiralPassword;
  errMessageList = [];

  constructor(
    private afFunc: AngularFireFunctions,
    public auth: AngularFireAuth,
    private cookieService: CookieService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.progressing = false;
    this.auth.onAuthStateChanged((user: firebase.User) => {
      user.getIdToken().then((idToken: string) => {
        console.log(idToken);
      });
    });
  }

  async login(): Promise<void> {
    this.clearError();
    this.progressing = true;
    this.afFunc
      .httpsCallable('loginWithSpiral')({
        mail: this.username,
        password: this.password,
      })
      .subscribe((res: any) => {
        if (res.err != '') {
          this.progressing = false;
          this.errMessageList = this.authErrorMessage(res.err);
        }
        this.cookieService.setDCMemberStatusCookie(res.data.dcMemberStatus);
        this.auth
          .signInWithCustomToken(res.data.token)
          .then(() => {
            if (res.data.isAdmin) {
              this.router.navigateByUrl('/fadmin/top');
            } else {
              this.router.navigateByUrl('/staff/top');
            }
          })
          .catch((reason) => {
            this.progressing = false;
            this.errMessageList = this.authErrorMessage(res.err);
          });
      });
  }

  logout(): void {
    this.cookieService.deleteDCMemberStatusCookie();
    this.auth.signOut();
  }

  loginAvaival(): boolean {
    return this.username != '' && this.password != '';
  }

  private authErrorMessage(reason: any): string[] {
    const errorCode = reason;
    switch (errorCode) {
      case 'Invalid identification information':
        return ['メールアドレス、またはパスワードが正しくありません。'];
      case 'Login locked':
        this.passwordForgotMessage = true;
        return ['ログインに5回連続で失敗したため、30分間ログインできません。'];
      default:
        return [
          'ログインに失敗しました。',
          'グループへのご参加状況や、メールアドレス・パスワードをご確認のうえ、再度ログイン操作を行ってください。',
        ];
    }
  }

  onClickPasswordHide(): void {
    this.passwordHide = !this.passwordHide;
  }

  onClickClearError(): void {
    this.clearError();
  }
  clearError(): void {
    this.errMessageList = [];
    this.passwordForgotMessage = false;
  }
}
