import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(
    private readonly ngCookieService: NgxCookieService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  deleteDCMemberStatusCookie() {
    this.ngCookieService.delete(
      'dcMemberStatus',
      '/',
      this.getRootDomain(),
      false,
      'Lax'
    );
  }

  setDCMemberStatusCookie(cookie: string) {
    this.ngCookieService.set('dcMemberStatus', cookie, {
      path: '/',
      domain: this.getRootDomain(),
      secure: false,
      sameSite: 'Lax',
    });
  }

  getRootDomain() {
    const hostname = this.document.location.hostname;
    if (hostname === 'localhost') {
      return hostname;
    }

    const temp = hostname.split('.').reverse();
    const rootDomain = '.' + temp[1] + '.' + temp[0];
    return rootDomain;
  }
}
