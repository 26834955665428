<section class="hero login is-fullheight">
  <div class="navbar-level has-shadow login">
    <div class="container">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item is-narrow">
            <a routerLink="/fadmin" class="logo">
              <img
                src="assets/image/site-logo-pc.png"
                width="218"
                height="56"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="hero-body">
    <div class="container login">
      <div class="has-text-centered">
        <h1 class="title">ディアケア ログイン</h1>
        <h2 class="subtitle">グループ管理者</h2>
      </div>

      <div class="container m-2">
        <div class="columns is-centered box my-2">
          <div class="column is-4">
            <div class="field m-2">
              <label class="label">メールアドレス</label>
              <div class="control">
                <input
                  class="input"
                  [(ngModel)]="username"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>

            <div class="field m-2">
              <label class="label">パスワード</label>
              <div class="control has-icons-right">
                <input
                  class="input"
                  [(ngModel)]="password"
                  [type]="passwordHide ? 'password' : 'text'"
                  placeholder=""
                />
                <span class="icon icon__password is-small is-right">
                  <i
                    class="mdi mdi-dark"
                    (click)="onClickPasswordHide()"
                    [class.mdi-eye-off]="passwordHide"
                    [class.mdi-eye]="!passwordHide"
                  ></i>
                </span>
              </div>
            </div>
            <span
              ><a
                href="{{ spiralPasswordReminderUrl }}"
                target="_blank"
                rel="noopener"
                >パスワードを忘れた方</a
              ></span
            >
          </div>
        </div>
        <div
          class="notification is-danger is-light has-text-centered"
          *ngIf="errMessageList.length > 0"
        >
          <button class="delete" (click)="onClickClearError()"></button>
          <ngcontainer *ngFor="let errMessage of errMessageList">
            <p>{{ errMessage }}</p>
          </ngcontainer>
          <ngcontainer *ngIf="passwordForgotMessage">
            href="spiralPasswordReminderUrl"
            <a href="{{ spiralPasswordReminderUrl }}">
              ※パスワードを忘れた方はこちら(パスワードをご変更いただいた後も、30分間はログインができません）</a
            >
          </ngcontainer>
        </div>
        <div class="columns is-centered mt-2">
          <div class="column is-full has-text-centered">
            <div class="field">
              <div class="control">
                <button
                  class="button is-link is-large is-respons"
                  (click)="login()"
                  [class.is-static]="!loginAvaival() || progressing"
                  [class.is-loading]="progressing"
                >
                  ログイン
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="is-title-bottom">
          <p>
            ※パスワードを5回連続で間違えると30分間ログインができなくなります。
          </p>
        </div>
      </div>
      <footer class="footer am-footer animate__animated animate__fadeIn">
        <p class="footer__copyright">©︎ALCARE Co.,Ltd.</p>
      </footer>
    </div>
  </div>
</section>
