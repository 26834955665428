<section class="hero is-light is-bold is-fullheight">
  <div class="hero-body">
    <div class="container">
      <div class="has-text-centered">
        <h1 class="title">サーバー管理者 ログイン</h1>
        <h2 class="subtitle">ログインしてください</h2>
      </div>

      <hr />

      <div class="columns is-centered">
        <div class="column is-4">
          <div class="field">
            <label class="label">メールアドレス</label>
            <div class="control">
              <input
                class="input"
                [(ngModel)]="username"
                type="text"
                placeholder=""
              />
            </div>
          </div>

          <div class="field">
            <label class="label">パスワード</label>
            <div class="control has-icons-right">
              <input
                class="input"
                [(ngModel)]="password"
                [type]="passwordHide ? 'password' : 'text'"
                placeholder=""
              />
              <span class="icon icon__password is-small is-right">
                <i
                  class="mdi mdi-dark"
                  (click)="onClickPasswordHide()"
                  [class.mdi-eye-off]="passwordHide"
                  [class.mdi-eye]="!passwordHide"
                ></i>
              </span>
            </div>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <button class="button is-link" (click)="login()">ログイン</button>
            </div>
            <div class="control">
              <button (click)="logout()" class="button is-link is-light">
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
